import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import validator from "validator";

export default function AddDetailInfo({
  setStep,
  itemData,
  timezone,
  startTimeHour,
  startTimeMin,
  dateValue,
  confirmDetail,
}) {
  const [dName, setDName] = useState("");
  const [dEmail, setDEmail] = useState("");
  const [dGuestEmail, setDGuestEmail] = useState("");
  const [dPhone, setDPhone] = useState("");
  const [dCompany, setDCompany] = useState("");
  const [dWebsite, setDWebsite] = useState("");
  const [dDesc, setDDesc] = useState("");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validatePhoneNumber = (number) => {
    if (number.length < 10) {
      return false;
    }
    const isValidPhoneNumber = validator.isMobilePhone(number);

    return isValidPhoneNumber;
  };

  const onConfirmDetail = () => {
    if (dName && dEmail && dPhone && dCompany && dWebsite && dDesc) {
      if (!isValidEmail(dEmail)) {
        NotificationManager.warning(
          "Email is not valid, please try again.",
          "Warning",
          3000
        );
      } else if (!validatePhoneNumber(dPhone)) {
        NotificationManager.warning(
          "Phone number is not valid, please try again.",
          "Warning",
          3000
        );
      } else {
        confirmDetail();
      }
    } else {
      console.log(dPhone);
      NotificationManager.warning(
        "Please set all fields for detail.",
        "Warning",
        3000
      );
    }
  };

  return (
    <div className="text-left">
      <NotificationContainer />
      <div>
        <button
          onClick={() => setStep("InitialPad")}
          type="button"
          className="text-purple-500 border border-purple-500 hover:bg-purple-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:focus:ring-perple-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>

          <span className="sr-only">Icon description</span>
        </button>
      </div>
      <div className="block sm:flex ">
        <div className="text-left w-full sm:w-1/3 border-r-2 p-2 pt-16">
          <img
            src="../img/logo/logo.png"
            width="80px"
            className="rounded-full"
            alt=""
          ></img>
          <h1 className="text-2xl font-bold mt-2">{itemData.title}</h1>
          <p className="flex mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p>
              {itemData.time} min
              <p className=" flex">
                {startTimeHour * 1} : {startTimeMin} ~ {startTimeHour * 1} :{" "}
                {startTimeMin * 1 + itemData.time * 1}, ({timezone}),{" "}
                {dateValue}
              </p>
            </p>
          </p>

          <p className="text-md mt-4 mb-10">
            <i>{itemData.desc}</i>
          </p>
        </div>

        <div className="w-full sm:w-2/3 px-4">
          <h1 className="text-center font-bold text-xl mt-6 ">Enter Details</h1>
          <div
            className="text-center mt-10  m-auto mt-10 overflow-auto"
            style={{ height: "400px" }}
          >
            <form className="w-1/2 m-auto">
              <div className="mb-2">
                <label className="">
                  <p className="text-left">Name*:</p>
                  <input
                    type="text"
                    className="p-2 w-full"
                    value={dName}
                    onChange={(e) => {
                      setDName(e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label className="">
                  <p className="text-left">Email*:</p>
                  <input
                    type="email"
                    className="p-2 w-full"
                    value={dEmail}
                    onChange={(e) => {
                      setDEmail(e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label className="">
                  <p className="text-left">Guest Email(s):</p>
                  <textarea
                    type="text"
                    className="p-2 w-full"
                    value={dGuestEmail}
                    onChange={(e) => {
                      setDGuestEmail(e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label className=" ">
                  <p className="text-left">Phone*:</p>
                  <PhoneInput
                    country={"us"}
                    value={dPhone}
                    onChange={(value) => {
                      setDPhone(value);
                    }}
                    className="w-full"
                  />
                </label>
              </div>
              <div className="mb-2">
                <label className=" ">
                  <p className="text-left">Company*:</p>
                  <input
                    type="email"
                    className="p-2 w-full"
                    value={dCompany}
                    onChange={(e) => {
                      setDCompany(e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label className=" ">
                  <p className="text-left">Company Website*:</p>
                  <input
                    type="email"
                    className="p-2 w-full"
                    value={dWebsite}
                    onChange={(e) => {
                      setDWebsite(e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label className="">
                  <p className="text-left">
                    Tell us more about your influencer marketing needs*:{" "}
                  </p>
                  <textarea
                    type="text"
                    className="p-2 w-full"
                    value={dDesc}
                    onChange={(e) => {
                      setDDesc(e.target.value);
                    }}
                  />
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="mt-10 float-right">
          <a
            onClick={onConfirmDetail}
            href="#_"
            className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-purple-500 rounded-full shadow-md group"
          >
            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-purple-500 group-hover:translate-x-0 ease">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                ></path>
              </svg>
            </span>
            <span className="absolute flex items-center justify-center w-full h-full text-purple-500 transition-all duration-300 transform group-hover:translate-x-full ease">
              Confirm
            </span>
            <span className="relative invisible">Confirm</span>
          </a>
        </div>
      </div>
    </div>
  );
}
