import React from "react";

const types = [
  {
    id: 1,
    title: "Discovery Call",
    desc: "We want to learn more about you! This short call gives you time to let us know about your needs to see if our product is right for you.",
    time: "15",
  },
  {
    id: 2,
    title: "Upfluence Software",
    desc: "Our team will facilitate a demo targeted to your needs. Learn more about how our product can work for you and your team!",
    time: "30",
  },
];

const InitialPad = ({ itemClick }) => {
  return (
    <div>
      <img
        src="../img/logo/logo.png"
        width="80px"
        className="text-center m-auto rounded-full"
        alt=""
      ></img>
      <h1 className="text-2xl font-bold mt-2">Upfluence</h1>
      <p className="text-md mt-4 mb-10">
        Welcome to Upfluence Software, please pick a time that is convenient for
        you.
      </p>
      <div className=" sm:flex w-3/4 m-auto border">
        {types.map((type, index) => {
          return (
            <div
              key={index}
              onClick={() => itemClick(type)}
              className="w-full sm:w-1/2 p-6 cursor-pointer hover:bg-[#98c48f]"
            >
              <div className="flex justify-between  ">
                <p className="font-bold text-lg">{type.title}</p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                  </svg>
                </p>
              </div>
              <p className="text-left mt-2">{type.desc}</p>
            </div>
          );
        })}
      </div>
      <p className="mt-40">
        <small className="gray">Welcome to Upfluence</small>
        <small className="gray ml-2">&copy;2022</small>
      </p>
    </div>
  );
};

export default InitialPad;
