import React, { useState } from "react";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
// import Select from "react-select";
// import DatePicker from "react-date-picker";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Calendar } from "primereact/calendar";
import classnames from "classnames";

export default function SelectDateTime({ itemData, setStep, confirmDateTime }) {
  const options = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
  ];

  

  const [timezone, setTimezone] = useState("");
  const [startTimeHour, setStartTimeHour] = useState("");
  const [startTimeMin, setStartTimeMin] = useState("");
  const [timeLong, setTimeLong] = useState("");
  const [dateValue, setDateValue] = useState("");

  const onSetTimeZone = (timezone) => {
    console.log(timezone);
    setTimezone(timezone);
  };
  const onSetStartTimePicker = (time) => {
    console.log(time);
    setTimeLong(time.toString());
    setStartTimeHour(time.split(":")[0]);
    setStartTimeMin(time.split(":")[1]);
  };
  const onSetDateValue = (date) => {
    if (Date.parse(date) < Date.parse(Date())) {
      NotificationManager.warning(
        "You have to select future date.",
        "Warning",
        3000
      );
    }
    console.log(Date.parse(Date()));
    console.log(Date.parse(date));
    setDateValue(date);
  };

  const onConfirmDateTime = () => {
    if (timezone && startTimeHour && startTimeMin && dateValue) {
      confirmDateTime(timezone, startTimeHour, startTimeMin, dateValue);
    } else {
      NotificationManager.warning(
        "Please confirm all fields.",
        "Warning",
        3000
      );
    }
  };

  return (
    <div className="text-left">
      <NotificationContainer />
      <div>
        <button
          onClick={() => setStep("InitialPad")}
          type="button"
          className="text-purple-500 border border-purple-500 hover:bg-purple-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:focus:ring-perple-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>

          <span className="sr-only">Icon description</span>
        </button>
      </div>
      <div className="block sm:flex ">
        <div className="text-left w-full sm:w-1/3 border-r-2 p-2 pt-16">
          <img
            src="../img/logo/logo.png"
            width="80px"
            className="rounded-full"
            alt=""
          ></img>
          <h1 className="text-2xl font-bold mt-2">{itemData.title}</h1>
          <p className="flex mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {itemData.time} min
          </p>
          <p className="text-md mt-4 mb-10">
            <i>{itemData.desc}</i>
          </p>
        </div>

        <div
          className="w-full sm:w-2/3 px-4 overflow-auto"
          style={{ maxHeight: "600px" }}
        >
          <p className="font-bold text-lg mb-2">Select Date & Time</p>
          <div className="block sm:flex ">
            <div className="w-full sm:w-2/3">
              <div className=" p-2">
                <p className="font-bold text-sm mb-2">Select Date</p>
                <Calendar
                  inline
                  value={dateValue}
                  onChange={(e) => onSetDateValue(e.value)}
                ></Calendar>
              </div>
              <div className=" p-2 ">
                {/* <Kronos date={currentDate} /> */}
                <p className="font-bold text-sm mb-2">Select TimeZone</p>
                <TimezonePicker
                  absolute={false}
                  value={timezone}
                  placeholder="Select timezone..."
                  onChange={(value) => {
                    onSetTimeZone(value);
                  }}
                  className="w-full h-9 mt-1  bg-transparent"
                />
              </div>
            </div>
            {dateValue.toString() && (
              <div className="w-full sm:w-1/3 ">
                <div className=" p-2">
                  <p>
                    {dateValue.toString().split(" ")[0] +
                      " " +
                      dateValue.toString().split(" ")[1] +
                      " " +
                      dateValue.toString().split(" ")[2] +
                      " " +
                      dateValue.toString().split(" ")[3]}
                  </p>
                  <p className="font-bold text-sm mb-2">Select Start Time</p>
                  {/* <Select
                    options={options}
                    onChange={(value) => onSetStartTimePicker(value)}
                    className="bg-transparent"
                  /> */}
                  {options.map((option, index) => {
                    return (
                      <div className="text-center" key={index}> 
                        <button onClick={() => onSetStartTimePicker(option.value)} className={classnames("bg-transparent w-2/3 m-auto mb-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded",{'active-btn-timeselector': timeLong === option.value})}>
                          {option.value}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="mt-10 m-right float-right">
          <a
            onClick={onConfirmDateTime}
            href="#_"
            className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-purple-500 rounded-full shadow-md group"
          >
            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-purple-500 group-hover:translate-x-0 ease">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                ></path>
              </svg>
            </span>
            <span className="absolute flex items-center justify-center w-full h-full text-purple-500 transition-all duration-300 transform group-hover:translate-x-full ease">
              Confirm
            </span>
            <span className="relative invisible">Confirm</span>
          </a>
        </div>
      </div>
    </div>
  );
}
