import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import { connect } from "react-redux";

// import { Provider } from "react-redux";
// import store from "./store";
import Home from "./views/Home";

function App() {
  return (
    <>
      <div
        className="area justify-center items-center bg-orange- via-orange-700 to-orange-800 bg-[#a855f7]"
        style={{
          position: "absolute",
        }}
      >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="flex justify-center items-center h-screen">
          <div
            className="shadow-2xl w-full lg:w-9/12 xl:w-7/12 bg-transparent rounded-xl p-8 m-auto bg-[#cbe0e6] z-40"
            style={{
              minHeight: "500px",
            }}
          >
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </Router>
          </div>
        </div>
      </div>
    </>
  );
}
export default App;
