import React, { useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export default function FinalConfirm({
  itemData,
  timezone,
  startTimeHour,
  startTimeMin,
  dateValue,
}) {
  useEffect(() => {
    NotificationManager.info("Appointment is confirmed!", "Confirm", 3000);
  }, []);

  const generateRandomString = () => Math.random().toString(36).slice(2)

  const randomLink = () => {
    var string = generateRandomString();
    return "https://upfluence.zoom.us/my/erikalinnevers/" + string;
  }
  return (
    <div className="w-full">
      <NotificationContainer />
      <div className="text-2xl font-bold mt-4">Confirmed</div>
      <img
        src="img/logo/logo1.png"
        className="mt-8 mb-4 text-center m-auto rounded-full"
        width="100px"
        height="100px"
        alt=""
      ></img>
      <div className="w-1/2 m-auto p-4">
        <hr></hr>
        <div className="p-4">
          <p className="text-lg font-bold">{itemData.title}</p>
          <p className="text-center mt-2 m-auto flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {startTimeHour * 1} : {startTimeMin} ~ {startTimeHour * 1} :{" "}
            {startTimeMin * 1 + itemData.time*1}, ({timezone}), {dateValue}
          </p>
          <p className="mt-2 flex text-center m-auto mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-4"
            >
              <path
                strokeLinecap="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
            <a href={randomLink()}> {randomLink()}</a>
          </p>

         
        </div>
        <hr></hr>
        <p className="mt-2">
            A calendar invitation has been sent to your email address.
          </p>
      </div>
    </div>
  );
}
