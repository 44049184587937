import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { type } from "@testing-library/user-event/dist/type";
import { render } from "@testing-library/react";

import SelectDateTime from "./SelectDateTime";
import InitialPad from "./InitialPad";
import AddDetailInfo from "./AddDetailInfo";
import FinalConfirm from "./FinalConfirm";

/**
 *
 */

function Home() {
  const [currentStep, setCurrentStep] = useState("initialPad");
  const [typeData, setTypeData] = useState({});
  const [timezone, setTimezone] = useState("");
  const [startTimeHour, setStartTimeHour] = useState("");
  const [startTimeMin, setStartTimeMin] = useState("");
  const [dateValue, setDateValue] = useState("");

  const renderSwitch = (step) => {
    switch (step) {
      case "initialPad":
        return (
          <InitialPad
            itemClick={(item) => {
              setCurrentStep("SelectDateTime");
              setTypeData(item);
            }}
          />
        );
      case "SelectDateTime":
        return (
          <SelectDateTime
            itemData={typeData}
            setStep={(step) => {
              setCurrentStep(step);
            }}
            confirmDateTime={(timezone, startTimeHour, startTimeMin, date) => {
              setTimezone(timezone);
              setStartTimeHour(startTimeHour);
              setStartTimeMin(startTimeMin);
              setDateValue(    date = date.toString().split(" ")[0]+" "+date.toString().split(" ")[1]+" "+date.toString().split(" ")[2]+" "+date.toString().split(" ")[3]);
              console.log(date);
              setCurrentStep("AddDetailInfo");
            }}
          />
        );
      case "AddDetailInfo":
        return (
          <AddDetailInfo
            setStep={(step) => {
              setCurrentStep(step);
            }}
            itemData={typeData}
            timezone={timezone}
            startTimeHour={startTimeHour}
            startTimeMin={startTimeMin}
            dateValue={dateValue}
            confirmDetail={() => {
              setCurrentStep("FinalConfirm");
            }}
          />
        );
      case "FinalConfirm":
        return (
          <FinalConfirm  itemData={typeData}
          timezone={timezone}
          startTimeHour={startTimeHour}
          startTimeMin={startTimeMin}
          dateValue={dateValue}/>
        )
      default:
        return (
          <InitialPad
            itemClick={(item) => {
              setCurrentStep("SelectDateTime");
              setTypeData(item);
            }}
          />
        );
    }
  };

  return <div className="text-center">{renderSwitch(currentStep)}</div>;
}

// #endregion

export default Home;
